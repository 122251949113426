var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var passes = ref.passes;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.save)}}},[_c('v-card-text',[_c('v-simple-table',{staticClass:"mb-4",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Médico")]),_c('th',[_vm._v("Consultorio")]),_c('th',[_vm._v("Fecha")]),_c('th',[_vm._v("Inicio")]),_c('th',[_vm._v("Fin")])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.turno.medico))]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.turno.consultorio))]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.turno.dia))]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.turno.hora_ini)+" hs.")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.turno.hora_fin)+" hs.")])])])]},proxy:true}],null,true)}),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"paciente_doc_tipo_id","name":"Tipo Documento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{ref:"paciente_doc_tipo_id",attrs:{"items":_vm.tiposDocumento,"item-value":"id","item-text":"descripcion","filled":"","dense":"","label":"Tipo Documento","required":"","error-messages":errors,"success":valid},model:{value:(_vm.paciente.doc_tipo_id),callback:function ($$v) {_vm.$set(_vm.paciente, "doc_tipo_id", $$v)},expression:"paciente.doc_tipo_id"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"paciente_doc_numero","name":"Número de Documento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"Número de Documento","filled":"","dense":"","required":"","error-messages":errors,"success":valid},model:{value:(_vm.paciente.doc_numero),callback:function ($$v) {_vm.$set(_vm.paciente, "doc_numero", $$v)},expression:"paciente.doc_numero"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"paciente_apellidos","name":"Apellidos","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"filled":"","label":"Apellidos","dense":"","required":"","error-messages":errors,"success":valid},model:{value:(_vm.paciente.apellidos),callback:function ($$v) {_vm.$set(_vm.paciente, "apellidos", $$v)},expression:"paciente.apellidos"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"paciente_nombres","name":"Nombres","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"filled":"","label":"Nombres","dense":"","required":"","error-messages":errors,"success":valid},model:{value:(_vm.paciente.nombres),callback:function ($$v) {_vm.$set(_vm.paciente, "nombres", $$v)},expression:"paciente.nombres"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-menu',{ref:"datepickerFechaNacimiento",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('ValidationProvider',{attrs:{"name":"Fecha Nacimiento","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{ref:"fechaNacimiento",attrs:{"type":"date","filled":"","dense":"","label":"Fecha Nacimiento","prepend-icon":"mdi-calendar","error-messages":errors,"success":valid},on:{"click:prepend":_vm.openDatepickerFechaNacimiento,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();$event.preventDefault();return _vm.openDatepickerFechaNacimiento.apply(null, arguments)}},model:{value:(_vm.paciente.fecha_nacimiento),callback:function ($$v) {_vm.$set(_vm.paciente, "fecha_nacimiento", $$v)},expression:"paciente.fecha_nacimiento"}})]}}],null,true)})]}}],null,true),model:{value:(_vm.datepickerFechaNacimiento),callback:function ($$v) {_vm.datepickerFechaNacimiento=$$v},expression:"datepickerFechaNacimiento"}},[_c('v-date-picker',{attrs:{"scrollable":""},on:{"change":_vm.closeDatepickerFechaNacimiento},model:{value:(_vm.paciente.fecha_nacimiento),callback:function ($$v) {_vm.$set(_vm.paciente, "fecha_nacimiento", $$v)},expression:"paciente.fecha_nacimiento"}})],1)],1),_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"paciente_email","name":"E-mail","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"email","filled":"","label":"E-mail","dense":"","error-messages":errors,"success":valid},model:{value:(_vm.paciente.email),callback:function ($$v) {_vm.$set(_vm.paciente, "email", $$v)},expression:"paciente.email"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"paciente_telefono_fijo","name":"Teléfono Fijo","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"filled":"","label":"Teléfono Fijo","dense":"","error-messages":errors,"success":valid},model:{value:(_vm.paciente.telefono_fijo),callback:function ($$v) {_vm.$set(_vm.paciente, "telefono_fijo", $$v)},expression:"paciente.telefono_fijo"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"paciente_telefono_movil","name":"Teléfono Móvil","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"filled":"","label":"Teléfono Móvil","dense":"","error-messages":errors,"success":valid},model:{value:(_vm.paciente.telefono_movil),callback:function ($$v) {_vm.$set(_vm.paciente, "telefono_movil", $$v)},expression:"paciente.telefono_movil"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"paciente_domicilio","name":"Domicilio","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"filled":"","label":"Domicilio","dense":"","error-messages":errors,"success":valid},model:{value:(_vm.paciente.domicilio),callback:function ($$v) {_vm.$set(_vm.paciente, "domicilio", $$v)},expression:"paciente.domicilio"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"paciente_localidad","name":"Localidad","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"filled":"","label":"Localidad","dense":"","error-messages":errors,"success":valid},model:{value:(_vm.paciente.localidad),callback:function ($$v) {_vm.$set(_vm.paciente, "localidad", $$v)},expression:"paciente.localidad"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"paciente_pais","name":"País","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"filled":"","label":"País","dense":"","error-messages":errors,"success":valid},model:{value:(_vm.paciente.pais),callback:function ($$v) {_vm.$set(_vm.paciente, "pais", $$v)},expression:"paciente.pais"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"paciente_obra_social_id","name":"Obra Social","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"items":_vm.obrasSociales,"item-value":"id","item-text":"nombre","filled":"","label":"Obra Social","dense":"","error-messages":errors,"success":valid},model:{value:(_vm.paciente.obra_social_id),callback:function ($$v) {_vm.$set(_vm.paciente, "obra_social_id", $$v)},expression:"paciente.obra_social_id"}})]}}],null,true)})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","color":"primary","dark":"","loading":_vm.saving}},[_vm._v("guardar asistencia")]),_c('v-btn',{staticClass:"mr-4",attrs:{"text":"","disabled":_vm.saving},on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_vm._v(" cancelar ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }