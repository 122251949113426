var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var passes = ref.passes;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.save)}}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"motivo_id","name":"Motivo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"items":_vm.motivos,"item-value":"id","item-text":"nombre","filled":"","dense":"","label":"Motivo","error-messages":errors,"success":valid},model:{value:(_vm.turno.motivo_id),callback:function ($$v) {_vm.$set(_vm.turno, "motivo_id", $$v)},expression:"turno.motivo_id"}})]}}],null,true)})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","color":"primary","dark":"","loading":_vm.saving}},[_vm._v("Guardar")]),_c('v-btn',{staticClass:"mr-4",attrs:{"text":"","disabled":_vm.saving},on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_vm._v(" cancelar ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }