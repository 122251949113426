var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var passes = ref.passes;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.save)}}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"medico_id","name":"Médico","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"autofocus":"","items":_vm.medicos,"item-value":"id","item-text":"nombre","filled":"","dense":"","label":"Médico","required":"","error-messages":errors,"success":valid},model:{value:(_vm.turno.medico_id),callback:function ($$v) {_vm.$set(_vm.turno, "medico_id", $$v)},expression:"turno.medico_id"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"consultorio_id","name":"Consultorio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"items":_vm.consultorios,"item-value":"id","item-text":"descripcion","filled":"","dense":"","label":"Consultorio","required":"","error-messages":errors,"success":valid},model:{value:(_vm.turno.consultorio_id),callback:function ($$v) {_vm.$set(_vm.turno, "consultorio_id", $$v)},expression:"turno.consultorio_id"}})]}}],null,true)})],1),_c('v-col',[_c('v-menu',{ref:"datepickerFecha",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('ValidationProvider',{attrs:{"name":"Fecha","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{ref:"fecha",attrs:{"type":"date","filled":"","dense":"","label":"Fecha","prepend-icon":"mdi-calendar","error-messages":errors,"success":valid},on:{"click:prepend":_vm.openDatepickerFecha,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();$event.preventDefault();return _vm.openDatepickerFecha.apply(null, arguments)}},model:{value:(_vm.turno.date),callback:function ($$v) {_vm.$set(_vm.turno, "date", $$v)},expression:"turno.date"}})]}}],null,true)})]}}],null,true),model:{value:(_vm.datepickerFecha),callback:function ($$v) {_vm.datepickerFecha=$$v},expression:"datepickerFecha"}},[_c('v-date-picker',{attrs:{"scrollable":""},on:{"change":_vm.closeDatepickerFecha},model:{value:(_vm.turno.date),callback:function ($$v) {_vm.$set(_vm.turno, "date", $$v)},expression:"turno.date"}})],1)],1),_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"start","name":"Inicio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"time","label":"inicio (obligatorio)","filled":"","dense":"","required":"","error-messages":errors,"success":valid},model:{value:(_vm.turno.start),callback:function ($$v) {_vm.$set(_vm.turno, "start", $$v)},expression:"turno.start"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"end","name":"Fin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"time","label":"fin (obligatorio)","filled":"","dense":"","required":"","error-messages":errors,"success":valid},model:{value:(_vm.turno.end),callback:function ($$v) {_vm.$set(_vm.turno, "end", $$v)},expression:"turno.end"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"motivo_id","name":"Motivo","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"items":_vm.motivos,"item-value":"id","item-text":"nombre","filled":"","dense":"","label":"Motivo","error-messages":errors,"success":valid},model:{value:(_vm.turno.motivo_id),callback:function ($$v) {_vm.$set(_vm.turno, "motivo_id", $$v)},expression:"turno.motivo_id"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"paciente_id","name":"Paciente","rules":_vm.pacienteSelected || !_vm.panelDataPaciente ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-autocomplete',{attrs:{"filled":"","items":_vm.pacientes,"loading":_vm.isLoadingPacientes,"search-input":_vm.searchPacientes,"label":"Buscar Paciente","placeholder":"Empieza a escribir para buscar","prepend-icon":"mdi-database-search","hide-no-data":_vm.pacientesHideNoData,"no-data-text":"No hay resultados, puede agregar un nuevo paciente haciendo click en el icono +","clearable":"","return-object":"","dense":"","item-value":"id","no-filter":"","error-messages":errors,"success":valid},on:{"update:searchInput":function($event){_vm.searchPacientes=$event},"update:search-input":function($event){_vm.searchPacientes=$event},"click:clear":_vm.resetDataPaciente,"change":_vm.setPaciente},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"])){ return null; }return _vm.resetDataPaciente.apply(null, arguments)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s([item.apellidos, item.nombres].join(', '))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s([item.apellidos, item.nombres].join(', '))+" ")]),_c('v-list-item-subtitle',[_c('span',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(item.doc_tipo_descripcion)+" "+_vm._s(item.doc_numero)+" ")])])],1)]}}],null,true),model:{value:(_vm.pacienteSelected),callback:function ($$v) {_vm.pacienteSelected=$$v},expression:"pacienteSelected"}})]}}],null,true)})],1),_c('v-col',{staticClass:"d-flex justify-center align-center mb-6",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":_vm.panelDataPaciente ? 'error' : 'primary',"icon":"","aria-label":_vm.panelDataPaciente  ? 'Cerrar paciente' : _vm.iconLabel,"title":_vm.panelDataPaciente  ? 'Cerrar paciente' : _vm.iconLabel},on:{"click":_vm.togglePanel}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.panelDataPaciente ? 'mdi-close' : _vm.iconName)+" ")])],1)],1)],1),_c('v-expand-transition',[(_vm.panelDataPaciente)?_c('div',[_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"paciente_doc_tipo_id","name":"Tipo Documento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{ref:"paciente_doc_tipo_id",attrs:{"items":_vm.tiposDocumento,"item-value":"id","item-text":"descripcion","filled":"","dense":"","label":"Tipo Documento","required":"","error-messages":errors,"success":valid},model:{value:(_vm.paciente.doc_tipo_id),callback:function ($$v) {_vm.$set(_vm.paciente, "doc_tipo_id", $$v)},expression:"paciente.doc_tipo_id"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"paciente_doc_numero","name":"Número de Documento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"Número de Documento","filled":"","dense":"","required":"","error-messages":errors,"success":valid},model:{value:(_vm.paciente.doc_numero),callback:function ($$v) {_vm.$set(_vm.paciente, "doc_numero", $$v)},expression:"paciente.doc_numero"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"paciente_apellidos","name":"Apellidos","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"filled":"","label":"Apellidos","dense":"","required":"","error-messages":errors,"success":valid},model:{value:(_vm.paciente.apellidos),callback:function ($$v) {_vm.$set(_vm.paciente, "apellidos", $$v)},expression:"paciente.apellidos"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"paciente_nombres","name":"Nombres","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"filled":"","label":"Nombres","dense":"","required":"","error-messages":errors,"success":valid},model:{value:(_vm.paciente.nombres),callback:function ($$v) {_vm.$set(_vm.paciente, "nombres", $$v)},expression:"paciente.nombres"}})]}}],null,true)})],1)],1),(_vm.obrasSociales.length > 1)?_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"paciente_obra_social_id","name":"Obra Social","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"items":_vm.obrasSociales,"item-value":"id","item-text":"nombre","filled":"","label":"Obra Social","dense":"","error-messages":errors,"success":valid},model:{value:(_vm.paciente.obra_social_id),callback:function ($$v) {_vm.$set(_vm.paciente, "obra_social_id", $$v)},expression:"paciente.obra_social_id"}})]}}],null,true)})],1)],1):_vm._e()],1):_vm._e()])],1),_c('v-divider'),(!_vm.$vuetify.breakpoint.xsOnly)?[_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","dark":"","loading":_vm.saving},on:{"click":_vm.deshabilitar}},[_vm._v("deshabilitar reserva")]),_c('v-btn',{attrs:{"type":"submit","color":"primary","dark":"","loading":_vm.saving}},[_vm._v("reservar")]),_c('v-btn',{staticClass:"mr-4",attrs:{"text":"","disabled":_vm.saving},on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_vm._v(" cancelar ")])],1)]:[_c('div',{staticClass:"d-flex flex-column"},[_c('v-btn',{staticClass:"mt-4",attrs:{"color":"error","dark":"","block":"","loading":_vm.saving},on:{"click":_vm.deshabilitar}},[_vm._v("deshabilitar reserva")]),_c('v-btn',{staticClass:"mt-4",attrs:{"type":"submit","color":"primary","dark":"","block":"","loading":_vm.saving}},[_vm._v("reservar")]),_c('v-btn',{staticClass:"mt-4",attrs:{"text":"","block":"","disabled":_vm.saving},on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_vm._v(" cancelar ")])],1)]],2)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }