<template>
<v-card>
  <v-container>
    <ValidationObserver ref="obs" v-slot="{ invalid, validated, passes }">
      <v-form @submit.prevent="passes(save)">
        <v-card-text>
          <v-simple-table class="mb-4" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Médico</th>
                  <th>Consultorio</th>
                  <th>Fecha</th>
                  <th>Inicio</th>
                  <th>Fin</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="font-weight-bold">{{ turno.medico }}</td>
                  <td class="font-weight-bold">{{ turno.consultorio }}</td>
                  <td class="font-weight-bold">{{ turno.dia }}</td>
                  <td class="font-weight-bold">{{ turno.hora_ini }} hs.</td>
                  <td class="font-weight-bold">{{ turno.hora_fin }} hs.</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-row>
            <v-col>
              <ValidationProvider vid="paciente_doc_tipo_id" name="Tipo Documento" rules="required" v-slot="{ errors, valid }">
                <v-select
                  ref="paciente_doc_tipo_id"
                  v-model="paciente.doc_tipo_id"
                  :items="tiposDocumento"
                  item-value="id"
                  item-text="descripcion"
                  filled
                  dense
                  label="Tipo Documento"
                  required
                  :error-messages="errors"
                  :success="valid"
                ></v-select>
              </ValidationProvider>
            </v-col>

            <v-col>
              <ValidationProvider vid="paciente_doc_numero" name="Número de Documento" rules="required" v-slot="{ errors, valid }">
                <v-text-field
                  v-model="paciente.doc_numero"
                  label="Número de Documento"
                  filled
                  dense
                  required
                  :error-messages="errors"
                  :success="valid"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col>
              <ValidationProvider vid="paciente_apellidos" name="Apellidos" rules="required" v-slot="{ errors, valid }">
                <v-text-field
                  filled
                  v-model="paciente.apellidos"
                  label="Apellidos"
                  dense
                  required
                  :error-messages="errors"
                  :success="valid"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col>
              <ValidationProvider vid="paciente_nombres" name="Nombres" rules="required" v-slot="{ errors, valid }">
                <v-text-field
                  filled
                  v-model="paciente.nombres"
                  label="Nombres"
                  dense
                  required
                  :error-messages="errors"
                  :success="valid"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-menu
                ref="datepickerFechaNacimiento"
                v-model="datepickerFechaNacimiento"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{}">
                  <ValidationProvider name="Fecha Nacimiento" rules="" v-slot="{ errors, valid }">
                    <v-text-field
                      type="date"
                      ref="fechaNacimiento"
                      v-model="paciente.fecha_nacimiento"
                      filled
                      dense
                      label="Fecha Nacimiento"
                      prepend-icon="mdi-calendar"
                      :error-messages="errors"
                      :success="valid"
                      v-on:click:prepend="openDatepickerFechaNacimiento"
                      v-on:keydown.enter.stop.prevent="openDatepickerFechaNacimiento"
                    ></v-text-field>
                  </ValidationProvider>
                </template>
                <v-date-picker
                  v-model="paciente.fecha_nacimiento"
                  scrollable
                  @change="closeDatepickerFechaNacimiento"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col>
              <ValidationProvider vid="paciente_email" name="E-mail" rules="email" v-slot="{ errors, valid }">
                <v-text-field
                  type="email"
                  filled
                  v-model="paciente.email"
                  label="E-mail"
                  dense
                  :error-messages="errors"
                  :success="valid"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col>
              <ValidationProvider vid="paciente_telefono_fijo" name="Teléfono Fijo" rules="" v-slot="{ errors, valid }">
                <v-text-field
                  filled
                  v-model="paciente.telefono_fijo"
                  label="Teléfono Fijo"
                  dense
                  :error-messages="errors"
                  :success="valid"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col>
              <ValidationProvider vid="paciente_telefono_movil" name="Teléfono Móvil" rules="" v-slot="{ errors, valid }">
                <v-text-field
                  filled
                  v-model="paciente.telefono_movil"
                  label="Teléfono Móvil"
                  dense
                  :error-messages="errors"
                  :success="valid"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <ValidationProvider vid="paciente_domicilio" name="Domicilio" rules="" v-slot="{ errors, valid }">
                <v-text-field
                  filled
                  v-model="paciente.domicilio"
                  label="Domicilio"
                  dense
                  :error-messages="errors"
                  :success="valid"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col>
              <ValidationProvider vid="paciente_localidad" name="Localidad" rules="" v-slot="{ errors, valid }">
                <v-text-field
                  filled
                  v-model="paciente.localidad"
                  label="Localidad"
                  dense
                  :error-messages="errors"
                  :success="valid"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col>
              <ValidationProvider vid="paciente_pais" name="País" rules="" v-slot="{ errors, valid }">
                <v-text-field
                  filled
                  v-model="paciente.pais"
                  label="País"
                  dense
                  :error-messages="errors"
                  :success="valid"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col>
              <ValidationProvider vid="paciente_obra_social_id" name="Obra Social" rules="" v-slot="{ errors, valid }">
                <v-select
                  v-model="paciente.obra_social_id"
                  :items="obrasSociales"
                  item-value="id"
                  item-text="nombre"
                  filled
                  label="Obra Social"
                  dense
                  :error-messages="errors"
                  :success="valid"
                ></v-select>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn type="submit"
            color="primary" dark
            :loading="saving">guardar asistencia</v-btn>
          <v-btn text :disabled="saving" class="mr-4" @click.stop="close">
            cancelar
          </v-btn>
        </v-card-actions>
      </v-form>
    </ValidationObserver>
  </v-container>
</v-card>
</template>

<script>

import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate"
//import _ from 'lodash'

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },

  name: 'TurnoAsistenciaForm',

  props: {
    turno: {},
    paciente: {},
  },

  data: () => ({
    saving: false,
    tiposDocumento: [],
    obrasSociales: [],
    datepickerFechaNacimiento: false,
    datepickerFecha: false,
  }),

  methods: {
    save () {
      this.saving = true

      let turno = {
        id: this.turno.id,
        se_presento: true,
        paciente: this.paciente,
      }

      this.$http.put('turnos/'+this.turno.id+'/asistencia', turno)
      .then((response) => {
        this.$eventHub.$emit('snackbar-message', 'El registro fue actualizado!')
        this.close(response.data)
      })
      .catch((error) => {
        switch (error.response.status) {
          case 400:
            this.$eventHub.$emit('snackbar-message', 'Hay errores en el formulario')

            this.$refs.obs.setErrors(error.response.data)

            break;
          default:
            this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud')
        }
      })
      .finally(() => {
        this.saving = false
      })
    },

    close: function (item = false) {
      this.$refs.obs.reset()
      this.$emit('cancel', item)
    },

    fetchTiposDocumentos () {
      this.$http.get('tipos-documento')
      .then((response) => {
        this.tiposDocumento = response.data
      })
      .catch((error) => { this.$eventHub.$emit('http-error', error) })
    },

    fetchObrasSociales () {
      this.$http.get('obras-sociales')
      .then((response) => {
        this.obrasSociales = response.data
      })
      .catch((error) => { this.$eventHub.$emit('http-error', error) })
    },

    openDatepickerFechaNacimiento () {
      this.datepickerFechaNacimiento = true
    },

    closeDatepickerFechaNacimiento () {
      this.datepickerFechaNacimiento = false

      setTimeout(() => {
        this.$refs.fechaNacimiento.$el.querySelector('input').focus()
      }, 10);
    },

    openDatepickerFecha () {
      this.datepickerFecha = true
    },

    closeDatepickerFecha () {
      this.datepickerFecha = false

      setTimeout(() => {
        this.$refs.fecha.$el.querySelector('input').focus()
      }, 10);
    },
  },

  created () {
    this.fetchTiposDocumentos()
    this.fetchObrasSociales()
  },
}
</script>
