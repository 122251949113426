<template>
<v-card>
  <v-container>
    <ValidationObserver ref="obs" v-slot="{ invalid, validated, passes }">
      <v-form @submit.prevent="passes(save)">
        <v-card-text>
          <v-row>
            <v-col>
              <ValidationProvider vid="motivo_id" name="Motivo" rules="required" v-slot="{ errors, valid }">
                <v-select
                  v-model="turno.motivo_id"
                  :items="motivos"
                  item-value="id"
                  item-text="nombre"
                  filled
                  dense
                  label="Motivo"
                  :error-messages="errors"
                  :success="valid"
                ></v-select>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn type="submit"
            color="primary" dark
            :loading="saving">Guardar</v-btn>
          <v-btn text :disabled="saving" class="mr-4" @click.stop="close">
            cancelar
          </v-btn>
        </v-card-actions>
      </v-form>
    </ValidationObserver>
  </v-container>
</v-card>
</template>

<script>

import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate"

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },

  name: 'TurnoUpdateForm',

  props: {
    turno: {},
  },

  data: () => ({
    saving: false,
    motivos: [],
  }),

  methods: {
    save () {
      this.saving = true

      let turno = {
        motivo_id: this.turno.motivo_id,
      }

      this.$http.put('turnos/'+this.turno.id, turno)
        .then((response) => {
          this.$eventHub.$emit('snackbar-message', 'El registro fue actualizado!')
          this.close(response.data)
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              break;
            case 422:
              this.$eventHub.$emit('snackbar-message', 'Hay errores en el formulario')

              this.$refs.obs.setErrors(error.response.data.errors);

              break;
            default:
              this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud')
          }
        })
        .finally(() => {
          this.saving = false
        })
    },

    close: function (item = false) {
      this.$refs.obs.reset()
      this.$emit('cancel', item)
    },

    fetchMotivos () {
      this.$http.get('motivos')
      .then((response) => {
        this.motivos = response.data
      })
      .catch((error) => { this.$eventHub.$emit('http-error', error) })
    },
  },

  created () {
    this.fetchMotivos()
  },

  //mounted () {},
}
</script>

<style scoped></style>
