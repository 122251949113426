<template>
<v-card>
  <v-container>
    <ValidationObserver ref="obs" v-slot="{ invalid, validated, passes }">
      <v-form @submit.prevent="passes(save)">
        <v-card-text>
          <v-row>
            <v-col>
              <ValidationProvider vid="medico_id" name="Médico" rules="required" v-slot="{ errors, valid }">
                <v-select
                  autofocus
                  v-model="turno.medico_id"
                  :items="medicos"
                  item-value="id"
                  item-text="nombre"
                  filled
                  dense
                  label="Médico"
                  required
                  :error-messages="errors"
                  :success="valid"
                ></v-select>
              </ValidationProvider>
            </v-col>

            <v-col>
              <ValidationProvider vid="consultorio_id" name="Consultorio" rules="required" v-slot="{ errors, valid }">
                <v-select
                  v-model="turno.consultorio_id"
                  :items="consultorios"
                  item-value="id"
                  item-text="descripcion"
                  filled
                  dense
                  label="Consultorio"
                  required
                  :error-messages="errors"
                  :success="valid"
                ></v-select>
              </ValidationProvider>
            </v-col>

            <v-col>
              <v-menu
                ref="datepickerFecha"
                v-model="datepickerFecha"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{}">
                  <ValidationProvider name="Fecha" rules="required" v-slot="{ errors, valid }">
                    <v-text-field
                      type="date"
                      ref="fecha"
                      v-model="turno.date"
                      filled
                      dense
                      label="Fecha"
                      prepend-icon="mdi-calendar"
                      :error-messages="errors"
                      :success="valid"
                      v-on:click:prepend="openDatepickerFecha"
                      v-on:keydown.enter.stop.prevent="openDatepickerFecha"
                    ></v-text-field>
                  </ValidationProvider>
                </template>
                <v-date-picker
                  v-model="turno.date"
                  scrollable
                  @change="closeDatepickerFecha"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col>
              <ValidationProvider vid="start" name="Inicio" rules="required"
                v-slot="{ errors, valid }">
                <v-text-field
                  v-model="turno.start"
                  type="time"
                  label="inicio (obligatorio)"
                  filled
                  dense
                  required
                  :error-messages="errors"
                  :success="valid"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col>
              <ValidationProvider vid="end" name="Fin" rules="required"
                v-slot="{ errors, valid }">
                <v-text-field
                  v-model="turno.end"
                  type="time"
                  label="fin (obligatorio)"
                  filled
                  dense
                  required
                  :error-messages="errors"
                  :success="valid"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <ValidationProvider vid="motivo_id" name="Motivo" rules="" v-slot="{ errors, valid }">
                <v-select
                  v-model="turno.motivo_id"
                  :items="motivos"
                  item-value="id"
                  item-text="nombre"
                  filled
                  dense
                  label="Motivo"
                  :error-messages="errors"
                  :success="valid"
                ></v-select>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <ValidationProvider vid="paciente_id" name="Paciente" :rules="pacienteSelected || !panelDataPaciente ? 'required' : ''" v-slot="{ errors, valid }">
                <v-autocomplete
                  filled
                  v-model="pacienteSelected"
                  :items="pacientes"
                  :loading="isLoadingPacientes"
                  :search-input.sync="searchPacientes"
                  label="Buscar Paciente"
                  placeholder="Empieza a escribir para buscar"
                  prepend-icon="mdi-database-search"
                  :hide-no-data="pacientesHideNoData"
                  no-data-text="No hay resultados, puede agregar un nuevo paciente haciendo click en el icono +"
                  clearable
                  @keydown.native.delete="resetDataPaciente"
                  @click:clear="resetDataPaciente"
                  return-object
                  dense
                  item-value="id"
                  @change="setPaciente"
                  no-filter
                  :error-messages="errors"
                  :success="valid"
                >
                  <template v-slot:selection="{ item }">
                    {{ [item.apellidos, item.nombres].join(', ') }}
                  </template>
                  <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ [item.apellidos, item.nombres].join(', ') }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <span class="text--primary">
                            {{ item.doc_tipo_descripcion }} {{ item.doc_numero }}
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                  </template>
                </v-autocomplete>
              </ValidationProvider>
            </v-col>
            <v-col cols="auto" class="d-flex justify-center align-center mb-6">
              <v-btn 
                @click="togglePanel"
                :color="panelDataPaciente ? 'error' : 'primary'"
                icon                 
                :aria-label="panelDataPaciente  ? 'Cerrar paciente' : iconLabel"
                :title="panelDataPaciente  ? 'Cerrar paciente' : iconLabel">
                <v-icon> {{ panelDataPaciente  ? 'mdi-close' : iconName }} </v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-expand-transition>
            <div v-if="panelDataPaciente">
              <v-row>
                <v-col>
                  <ValidationProvider vid="paciente_doc_tipo_id" name="Tipo Documento" rules="required" v-slot="{ errors, valid }">
                    <v-select
                      ref="paciente_doc_tipo_id"
                      v-model="paciente.doc_tipo_id"
                      :items="tiposDocumento"
                      item-value="id"
                      item-text="descripcion"
                      filled
                      dense
                      label="Tipo Documento"
                      required
                      :error-messages="errors"
                      :success="valid"
                    ></v-select>
                  </ValidationProvider>
                </v-col>

                <v-col>
                  <ValidationProvider vid="paciente_doc_numero" name="Número de Documento" rules="required" v-slot="{ errors, valid }">
                    <v-text-field
                      v-model="paciente.doc_numero"
                      label="Número de Documento"
                      filled
                      dense
                      required
                      :error-messages="errors"
                      :success="valid"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <ValidationProvider vid="paciente_apellidos" name="Apellidos" rules="required" v-slot="{ errors, valid }">
                    <v-text-field
                      filled
                      v-model="paciente.apellidos"
                      label="Apellidos"
                      dense
                      required
                      :error-messages="errors"
                      :success="valid"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col>
                  <ValidationProvider vid="paciente_nombres" name="Nombres" rules="required" v-slot="{ errors, valid }">
                    <v-text-field
                      filled
                      v-model="paciente.nombres"
                      label="Nombres"
                      dense
                      required
                      :error-messages="errors"
                      :success="valid"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row v-if="obrasSociales.length > 1">
                <v-col>
                  <ValidationProvider vid="paciente_obra_social_id" name="Obra Social" rules="required" v-slot="{ errors, valid }">
                    <v-select
                      v-model="paciente.obra_social_id"
                      :items="obrasSociales"
                      item-value="id"
                      item-text="nombre"
                      filled
                      label="Obra Social"
                      dense
                      :error-messages="errors"
                      :success="valid"
                    ></v-select>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </div>
          </v-expand-transition>
        </v-card-text>

        <v-divider></v-divider>

        <template v-if="!$vuetify.breakpoint.xsOnly">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn 
              color="error" dark
              :loading="saving" @click="deshabilitar">deshabilitar reserva</v-btn>
            <v-btn type="submit"
              color="primary" dark
              :loading="saving">reservar</v-btn>
            <v-btn text :disabled="saving" class="mr-4" @click.stop="close">
              cancelar
            </v-btn>
          </v-card-actions>
        </template>

        <template v-else>
          <div class="d-flex flex-column">
            <v-btn 
              color="error" dark block
              class="mt-4"
              :loading="saving" @click="deshabilitar">deshabilitar reserva</v-btn>
            <v-btn type="submit"
              color="primary" dark block
              class="mt-4"
              :loading="saving">reservar</v-btn>
            <v-btn text block :disabled="saving" class="mt-4" @click.stop="close">
              cancelar
            </v-btn>
          </div>
        </template>
      </v-form>
    </ValidationObserver>
  </v-container>
</v-card>
</template>

<script>

import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate"
import _ from 'lodash'

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },

  name: 'TurnoCreateForm',

  props: {
    medicos: [],
    turno: {},
    paciente: {},
  },

  data: () => ({
    saving: false,
    consultorios: [],
    tiposDocumento: [],
    obrasSociales: [],
    pacientes: [],
    motivos: [],
    pacienteSelected: null,
    isLoadingPacientes: false,
    searchPacientes: '',
    pacientesHideNoData: true,
    datepickerFechaNacimiento: false,
    datepickerFecha: false,
    panelDataPaciente: false,
    iconLabel: 'Agregar paciente',
    iconName: 'mdi-plus'
  }),

  watch: {
    panelDataPaciente (val) {
      if (val && (this.obrasSociales.length === 1)) {
        this.paciente.obra_social_id = this.obrasSociales[0].id
      }
    },

    searchPacientes (val) {
      if (val && val.length > 1) {
        this.pacientesHideNoData = false
        //this.debouncePacientes(val)
        this.fetchPacientes(val);
      } else {
        this.pacientesHideNoData = true
        this.pacientes = [];
      }
    },

    pacienteSelected (val) {
      this.iconLabel = 'Agregar paciente'
      this.iconName = 'mdi-plus'

      if (val) {
        this.iconLabel = 'Editar paciente'
        this.iconName = 'mdi-pencil'
      }
    }
  },

  methods: {
    save () {
      this.saving = true

      let turno = {
        medico_id: this.turno.medico_id,
        consultorio_id: this.turno.consultorio_id,
        inicio: this.turno.date + ' ' + this.turno.start + ':00',
        fin: this.turno.date + ' ' + this.turno.end + ':00',
        motivo_id: this.turno.motivo_id,
        //se_presento: this.turno.se_presento,
        paciente: this.paciente
      }

      this.$http.post('turnos', turno)
        .then((response) => {
          this.$eventHub.$emit('snackbar-message', 'El registro fue creado!')
          this.close(response.data)
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              break;
            case 422:
              this.$eventHub.$emit('snackbar-message', 'Hay errores en el formulario')

              this.$refs.obs.setErrors(error.response.data.errors);

              break;
            default:
              this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud')
          }
        })
        .finally(() => {
          this.saving = false
        })
    },

    close: function (item = false) {
      this.$refs.obs.reset()
      //this.$nextTick(() => {
        this.clearSearchPacientes()
        this.panelDataPaciente = false
      //})
      this.$emit('cancel', item)
    },

    fetchConsultorios () {
      this.$http.get('consultorios')
      .then((response) => {
        this.consultorios = response.data
      })
      .catch((error) => { this.$eventHub.$emit('http-error', error) })
    },

    setPaciente (selected) {
      if (selected) {
        this.paciente.id = selected.id
        this.paciente.doc_tipo_id = selected.doc_tipo_id
        this.paciente.doc_numero = selected.doc_numero
        this.paciente.apellidos = selected.apellidos
        this.paciente.nombres = selected.nombres
        this.paciente.fecha_nacimiento = selected.fecha_nacimiento
        this.paciente.email = selected.email
        this.paciente.telefono_fijo = selected.telefono_fijo
        this.paciente.telefono_movil = selected.telefono_movil
        this.paciente.domicilio = selected.domicilio
        this.paciente.localidad = selected.localidad
        this.paciente.pais = selected.pais
        this.paciente.obra_social_id = selected.obra_social_id

        //this.$refs.paciente_doc_tipo_id.focus();
      }

      /*
      this.$nextTick(() => {
        this.clearSearchPacientes()
      })*/
    },

    clearSearchPacientes () {
      this.pacienteSelected = null
      this.pacientes = []
    },

    debouncePacientes: _.debounce(function (val) {
      this.fetchPacientes(val)
    }, 1000),

    fetchPacientes(search = '') {
      this.isLoadingPacientes = true;

      let filters = {
        filter: search,
        per_page: 10,
        page: 1,
      };

      this.$http.get('pacientes', {params: filters})
      .then((response) => {
        this.pacientes = response.data
      })
      .catch((error) => { this.$eventHub.$emit('http-error', error) })
      .then(() => {
        this.isLoadingPacientes = false;
      })
    },

    fetchTiposDocumentos () {
      this.$http.get('tipos-documento')
      .then((response) => {
        this.tiposDocumento = response.data

        if (this.obrasSociales.length > 1) {
          this.paciente.doc_tipo_id = ''
        }
      })
      .catch((error) => { this.$eventHub.$emit('http-error', error) })
    },

    fetchObrasSociales () {
      this.$http.get('obras-sociales')
      .then((response) => {
        this.obrasSociales = response.data

        if (this.obrasSociales.length === 1) {
          this.paciente.obra_social_id = this.obrasSociales[0].id
        }
      })
      .catch((error) => { this.$eventHub.$emit('http-error', error) })
    },

    openDatepickerFechaNacimiento () {
      this.datepickerFechaNacimiento = true
    },

    closeDatepickerFechaNacimiento () {
      this.datepickerFechaNacimiento = false

      setTimeout(() => {
        this.$refs.fechaNacimiento.$el.querySelector('input').focus()
      }, 10);
    },

    openDatepickerFecha () {
      this.datepickerFecha = true
    },

    closeDatepickerFecha () {
      this.datepickerFecha = false

      setTimeout(() => {
        this.$refs.fecha.$el.querySelector('input').focus()
      }, 10);
    },

    deshabilitar () {
      this.saving = true

      let turno = {
        medico_id: this.turno.medico_id,
        consultorio_id: this.turno.consultorio_id,
        inicio: this.turno.date + ' ' + this.turno.start + ':00',
        fin: this.turno.date + ' ' + this.turno.end + ':00',
        //se_presento: this.turno.se_presento,
      }

      this.$http.post('turnos/deshabilitar', turno)
        .then((response) => {
          this.$eventHub.$emit('snackbar-message', 'El registro fue deshabilitado!')
          this.close(response.data)
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              break;
            case 422:
              this.$eventHub.$emit('snackbar-message', 'Hay errores en el formulario')

              this.$refs.obs.setErrors(error.response.data.errors);

              break;
            default:
              this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud')
          }
        })
        .finally(() => {
          this.saving = false
        })
    },
    
    fetchMotivos () {
      this.$http.get('motivos')
      .then((response) => {
        this.motivos = response.data
      })
      .catch((error) => { this.$eventHub.$emit('http-error', error) })
    },

    resetDataPaciente () {
      this.paciente.doc_numero = ''
      this.paciente.nombres = ''
      this.paciente.apellidos = ''
    },

    togglePanel() {
      this.panelDataPaciente = !this.panelDataPaciente;
    }
  },

  created () {
    this.fetchConsultorios()
    this.fetchMotivos()
    this.fetchTiposDocumentos()
    this.fetchObrasSociales()
  },
}
</script>

<style scoped></style>
